import permormanceImg from "../../../images/mlp/performance-driven.png";
import practicalImg from "../../../images/mlp/practical.png";
import interviewIcon from '../../../../static/assets/interviewIcon.webp';
export const overviewpoints = [
  {
    id: "1",
    mobImg:
    practicalImg,
    deskImg:
    practicalImg,
    name: "Practical",
    description: `<div>
        Since many of the critical concepts in sales and marketing become
        intelligible only when candidates start solving real problems,<b> we
        design our programs project-out</b>. Each program has project/s which
        students start working on from day 1 of the program, in groups of
        3-4. The learning modules serve a guide on how to solve the tasks,
        and discussions with group members enhance the experience for
        every student.
      </div>
      <div class="mtop-2">
        <b>Based on the 70:20:10 learning model</b>, in Kraftshala programs, the
        project contributes to 70% of the learning process and the content
        contributes to 10% of the learning. The 20% is covered under personalised learning. 
      </div>`,
  },
  {
    id: "2",
    mobImg:
    interviewIcon,
    deskImg:
    interviewIcon,
    name: "Personalised",
    description: ` <div>
    <b>Our students experience a warm and personalised student journey</b>
    because we have created automated checkpoints that trigger
    interventions from the academic and operations teams to ensure
    high quality learning outcomes.
  </div>
  <div class="mtop-2">
    For instance, students are required to rate each coach
    interaction. These ratings, along with qualitative feedback, are
    shared automatically with the coach, with their incentives tied to
    average student rating.
  </div>
  <div class="mtop-2">
    The programs are also personalised because students get to connect
    with top industry experts and discuss your learnings every week
    and in the case of our full-time program, every day. <b>This is also
    the remaining 20% part of the 70:20:10 learning model.</b>
  </div>`,
  },
  {
    id: "3",
    mobImg:
    permormanceImg,
    deskImg:
    permormanceImg,
    name: "Performance Driven",
    description: `<div>
    <b>
    Every program’s career support is mapped to the audience’s needs.
    </b>
  </div>
  <div class="mtop-2">
    In the case of programs for fresh graduates who have no experience
    or networks, we own the end-to-end placement process, connecting
    with recruiters to deliver 100% placements between 4.5 - 8 LPA.
  </div>
  <div class="mtop-2">
    In the case of working professionals, the support starts with
    helping them sharpen their profile based on a focused career
    strategy, and then extends to helping them get shortlisted and
    finally get on the fastest path towards marketing leadership roles
    We call this approach, CAMP: Career Acceleration Module for
    Professionals.
  </div>`,
  },
];
